body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, MyriadPro-Regular, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: "Bankia-Bold";
  src: url("./assets/fonts/Bankia-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "Bankia-Bold-Italic";
  src: url("./assets/fonts/Bankia-Bold-Italic.ttf") format("truetype");
}
@font-face {
  font-family: "Bankia-Heavy";
  src: url("./assets/fonts/Bankia-Heavy.ttf") format("truetype");
}
@font-face {
  font-family: "Bankia-Heavy-Italic";
  src: url("./assets/fonts/Bankia-Heavy-Italic.ttf") format("truetype");
}
@font-face {
  font-family: "Bankia-Italic";
  src: url("./assets/fonts/Bankia-Italic.ttf") format("truetype");
}
@font-face {
  font-family: "Bankia-Light";
  src: url("./assets/fonts/Bankia-Light.ttf") format("truetype");
}

@font-face {
  font-family: "Bankia-Medium";
  src: url("./assets/fonts/Bankia-Medium.ttf") format("truetype");
}
@font-face {
  font-family: "Bankia-Medium-Italic";
  src: url("./assets/fonts/Bankia-Medium-Italic.ttf") format("truetype");
}
@font-face {
  font-family: "Bankia-Regular";
  src: url("./assets/fonts/Bankia-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "SourceSansPro-Black";
  src: url("./assets/fonts/SourceSansPro-Black.ttf") format("truetype");
}
@font-face {
  font-family: "SourceSansPro-Bold";
  src: url("./assets/fonts/SourceSansPro-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "SourceSansPro-ExtraLight";
  src: url("./assets/fonts/SourceSansPro-ExtraLight.ttf") format("truetype");
}
@font-face {
  font-family: "SourceSansPro-Italic";
  src: url("./assets/fonts/SourceSansPro-Italic.ttf") format("truetype");
}
@font-face {
  font-family: "SourceSansPro-Light";
  src: url("./assets/fonts/SourceSansPro-Light.ttf") format("truetype");
}
@font-face {
  font-family: "SourceSansPro-Regular";
  src: url("./assets/fonts/SourceSansPro-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "SourceSansPro-SemiBold";
  src: url("./assets/fonts/SourceSansPro-SemiBold.ttf") format("truetype");
}

body {
  font-family: "SourceSansPro-Regular", "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
    "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: "#000";
}
